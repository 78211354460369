@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply text-gray-600;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;

}

@layer base {
  :root {

        --background: 0 0% 100%;

        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;

        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;

        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;

        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;

        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;

        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;

        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;

        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;

        --input: 0 0% 89.8%;

        --ring: 0 0% 3.9%;

        --chart-1: 12 76% 61%;

        --chart-2: 173 58% 39%;

        --chart-3: 197 37% 24%;

        --chart-4: 43 74% 66%;

        --chart-5: 27 87% 67%;

        --radius: 0.5rem
  }
  .dark {
        --background: 0 0% 3.9%;

        --foreground: 0 0% 98%;

        --card: 0 0% 3.9%;

        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;

        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;

        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;

        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;

        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;

        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;

        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;

        --input: 0 0% 14.9%;

        --ring: 0 0% 83.1%;

        --chart-1: 220 70% 50%;

        --chart-2: 160 60% 45%;

        --chart-3: 30 80% 55%;

        --chart-4: 280 65% 60%;

        --chart-5: 340 75% 55%
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@media screen and (max-width: 389px) {
      html {
            font-size: 14px;
      }
}

.cky-btn-revisit-wrapper {
    @apply bg-neutral-400 !important;
    @apply dark:bg-neutral-800 !important;
}

.cky-btn-revisit-wrapper:before {
    @apply bg-neutral-400 !important;
    @apply dark:bg-neutral-800 !important;
}

.cky-btn-revisit-wrapper:after {
    @apply border-r-neutral-400 !important;
    @apply dark:border-r-neutral-800 !important;
}

.cky-modal,
.cky-preference-center {
    @apply bg-white !important;
    @apply dark:bg-neutral-800 !important;
}

.cky-preference-content-wrapper,
.cky-dma-content-wrapper,
.cky-accordion-header-des,
.cky-accordion-btn,
.cky-preference-title {
    @apply text-neutral-900 !important;
    @apply dark:text-neutral-300 !important;
}

.cky-cookie-des-table {
    @apply dark:bg-neutral-700 !important;
}

.cky-btn {
    @apply rounded-md !important;
}

.cky-btn.cky-btn-accept {
    @apply font-semibold !important;
    @apply text-white !important;
    @apply dark:text-neutral-800 !important;
}

.cky-btn.cky-btn-preferences,
.cky-btn.cky-btn-customize,
.cky-btn.cky-btn-reject {
    @apply border-neutral-700 !important;
    @apply text-neutral-700 !important;
    @apply dark:text-neutral-300 !important;
    @apply dark:border-neutral-300 !important;
}

.cky-footer-shadow {
    @apply bg-gradient-to-b from-transparent to-white !important;
    @apply dark:to-neutral-900 !important;
}

.cky-consent-bar {
    @apply bg-white !important;
    @apply border-neutral-200 !important;
    @apply dark:bg-neutral-900 !important;
    @apply dark:border-neutral-800 !important;
    @apply dark:shadow-none !important;
}

.cky-title,
.cky-notice-des {
    @apply text-neutral-900 !important;
    @apply dark:text-neutral-300 !important;
}

[data-cky-tag="detail-powered-by"] {
    @apply dark:bg-neutral-300 !important;
}

a.cky-banner-element {
    padding: 8px 30px;
    background: #F8F9FA;
    color: #858A8F;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}